import React from 'react';
import {Row, Col} from 'antd';
import Layout from '~components/layout';
import Seo from '~components/seo';
import AccountSideBar from '~components/account/sidebar';
import ConsumerAuth from '~containers/authenticate/consumer-auth';
import {firebaseAnalytics} from '~utils/firebase';
import CusBreadcrumbs from '~components/breadcrumbs';
import AccountHeader from '~components/account/header';
import Address from '~containers/account/address';

import '../styles.scss';

const AddressPage = props => {
  React.useEffect(() => {
    firebaseAnalytics.logEvent('Address_page_visited');
  }, []);

  const title = 'Địa chỉ giao hàng';

  return (
    <ConsumerAuth>
      <Layout>
        <Seo title={title} />
        <Row justify='center' className='category-path com-bg-gray'>
          <Col xs={24} md={22} lg={21}>
            <CusBreadcrumbs items={['Thông tin tài khoản', title]} />
          </Col>
        </Row>
        <Row justify='center'>
          <Col xs={24} md={22} lg={21}>
            <div id='account-page'>
              <AccountSideBar activeItemURL={props.uri} />
              <div className='account-content'>
                <AccountHeader title={title} />
                <Address />
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    </ConsumerAuth>
  );
};

export default AddressPage;
