// extracted by mini-css-extract-plugin
export var addressItem = "styles-module--addressItem--mx8QM";
export var addressName = "styles-module--addressName--onseB";
export var btnArea = "styles-module--btnArea--TVPLp";
export var btnDelete = "styles-module--btnDelete--7Cw3m";
export var btnEdit = "styles-module--btnEdit--Ypn8i";
export var phoneNumber = "styles-module--phone-number--26Bpt";
export var tags = "styles-module--tags--TzbI4";
export var titleHeader = "styles-module--titleHeader--EXcyx";
export var uppercase = "styles-module--uppercase--MSltc";
export var username = "styles-module--username--kARXu";