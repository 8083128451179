import {Tag} from 'antd';
import React from 'react';
import {AddressType} from '~utils/enum';

import * as styles from './styles.module.css';

const AddressItem = ({address, openUpdateMode, handleDelete}) => {
  const getFullAddress = () => {
    return `${address.street ? `${address.street}, ` : ''}${address.ward}, ${address.district}, ${address.city}`;
  };

  return (
    <div className={styles.addressItem}>
      <div className={styles.titleHeader}>
        <div className={styles.title}>
          <span className={styles.username}>{address.name}</span>
          <span className={styles.phoneNumber}>{address.mobileNo}</span>
        </div>
        <div className={styles.btnArea}>
          <div className={styles.btnEdit} onClick={openUpdateMode}>
            Sửa
          </div>
          <span>|</span>
          <div className={styles.btnDelete} onClick={handleDelete}>
            Xóa
          </div>
        </div>
      </div>
      <div className={styles.addressName}>{getFullAddress()}</div>
      <div className={styles.tags}>
        {address.addressType &&
          Object.keys(AddressType).findIndex(x => AddressType[x][0] === address.addressType) > -1 && (
            <Tag className={styles.uppercase}>
              {AddressType[Object.keys(AddressType).find(x => AddressType[x][0] === address.addressType)][1]}
            </Tag>
          )}
        {address.isDefault && (
          <Tag color='blue' className={styles.uppercase}>
            Mặc định
          </Tag>
        )}
      </div>
    </div>
  );
};

export default AddressItem;
