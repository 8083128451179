import {message} from 'antd';
import {navigate} from 'gatsby';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {get, remove} from '~utils/api';
import AddressItem from './address-item';

import * as styles from './styles.module.css';

const AddressComponent = ({consumer}) => {
  const [addresses, setAddresses] = useState([]);

  const getAddresses = async () => {
    const res = await get('/v1/customer/address');
    if (!res) return;
    setAddresses(res.items);
  };

  useEffect(() => {
    if (consumer) {
      getAddresses();
    }
  }, [consumer]);

  const handleUpdateMode = item => {
    navigate(`edit/${item.id}`);
  };

  const handleDeleteAddress = async id => {
    const res = await remove(`/v1/customer/address/${id}`, {id});
    if (res) message.success('Xóa địa chỉ thành công!');
    await getAddresses();
  };

  return (
    <div className={styles.content}>
      {addresses &&
        addresses.map((x, i) => (
          <div key={x.id}>
            <AddressItem
              address={x}
              openUpdateMode={() => handleUpdateMode(x)}
              handleDelete={() => handleDeleteAddress(x.id)}
            />
            {i < addresses.length - 1 && <div className={styles.borderLine} />}
          </div>
        ))}
      <div className={styles.btnAdd} onClick={() => navigate('create')}>
        Thêm địa chỉ
      </div>
    </div>
  );
};

export default connect(state => ({
  consumer: state.user.consumer,
}))(AddressComponent);
